import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { getStorage, ref, deleteObject } from 'firebase/storage';
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  doc,
  deleteDoc,
  getDoc,
  updateDoc,
} from 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage(app);

const db = getFirestore();

export async function getCollectionsData(collectionName) {
  const querySnapshot = await getDocs(collection(db, collectionName));
  const data = querySnapshot.docs.map((document) => ({
    id: document.id,
    ...document.data(),
  }));
  return data;
}

export async function getDataWithSubCollection(
  collectionName,
  subCollectionName,
) {
  const data = await getCollectionsData(collectionName);

  const dataWithSubCollection = await Promise.all(
    data.map(async (document) => ({
      ...document,
      [subCollectionName]: await getCollectionsData(
        `${collectionName}/${document.id}/${subCollectionName}`,
      ),
    })),
  );

  return dataWithSubCollection;
}

export async function addDataToCollection(collectionName, data) {
  const newDocRef = await addDoc(collection(db, collectionName), data);
  const docSnap = await getDoc(newDocRef);
  return {
    id: docSnap.id,
    ...docSnap.data(),
  };
}

export async function addDataToSubCollection(subCollectionRoute, data) {
  const newDocRef = await addDoc(collection(db, subCollectionRoute), data);
  const docSnap = await getDoc(newDocRef);
  return {
    id: docSnap.id,
    ...docSnap.data(),
  };
}

export async function editDocumentInCollection(collectionName, id, body) {
  await updateDoc(doc(db, collectionName, id), body);
}

export async function editDocumentInSubCollection(
  subCollectionRoute,
  id,
  body,
) {
  await updateDoc(doc(db, subCollectionRoute, id), body);
}

export async function deleteDocument(collectionName, id) {
  await deleteDoc(doc(db, collectionName, id));
}

export async function deleteDocumentFromSubCollection(subCollectionRoute, id) {
  await deleteDoc(doc(db, subCollectionRoute, id));
}

export async function signIn(email, password) {
  await signInWithEmailAndPassword(auth, email, password);
}

export async function userSignOut() {
  await signOut(auth);
}

export function removeImageFromStore(url, setError) {
  if (!url || !url.includes('firebasestorage')) {
    return;
  }
  const prevFileRef = ref(storage, url);
  deleteObject(prevFileRef).catch((error) => setError(error));
}
