export {
  HeadingH1,
  HeadingH2,
  HeadingH3,
  HeadingH4,
  HeadingH5,
  HeadingH5Bold,
  HeadingH6,
  HeadingH6Bold,
  HeadingLinks,
  Subtitle1,
  Subtitle2,
  Subtitle1Bold,
  Body1,
  Body1Bold,
  Body2,
  Body1ExtraBold,
} from './style';
